import { useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { LayoutMain } from "src/components/AppLayout/LayoutMain"
import { EmptyState } from "src/components/EmptyState"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import Svg404 from "src/ui/icons/404.svg"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { spacing } from "src/ui/spacing"

import { HomeAddressWizard } from "./HomeAddressWizard"

export function HomeAddressEdit() {
  const { homeId } = useParams<{ homeId: string }>()
  const { org } = useOrganization()
  const [error, setError] = useState(false)
  const { t, langKeys } = useTranslate()

  const fetchHome = useFetchHome({
    orgId: org.id,
    homeId,
    options: {
      onError: () => setError(true),
    },
  })

  if (error) {
    return (
      <LayoutMain>
        <EmptyState
          title={t(langKeys.failed_general_error_title)}
          body={t(langKeys.failed_general_error_body)}
          icon={<Svg404 />}
        />
      </LayoutMain>
    )
  }

  return (
    <div>
      {fetchHome.isLoading || !fetchHome.data ? (
        <Fetching>
          <MCircularProgress size={35} />
        </Fetching>
      ) : (
        <HomeAddressWizard
          homeId={homeId}
          address={fetchHome.data.address}
          location={fetchHome.data.location}
          responseServiceActive={fetchHome.data.response_service?.active}
          emergencyContact={fetchHome.data.emergency_contact}
        />
      )}
    </div>
  )
}

const Fetching = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.XL};
  justify-content: center;
`
