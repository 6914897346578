import { useState } from "react"

import { AxiosError } from "axios"

import {
  HOME_EMERGENCY_CONTACT_FORM_ID,
  HomeEmergencyContactStep,
} from "src/components/Homes/HomeAddress/HomeEmergencyContactStep"
import {
  IEmergencyContact,
  ILocation,
  THome,
  THomeAddress,
} from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { ResponseServiceConfigurationError } from "src/data/responseService/types/responseServiceTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { ErrorService } from "src/utils/ErrorService"
import { getTimeZoneFromLoc } from "src/utils/l10n"

import { HOME_ADDRESS_FORM_ID, HomeAddressStep } from "./HomeAddressStep"
import { HomeMapStep } from "./HomeMapStep"

export function HomeAddressWizard({
  homeId,
  address,
  location,
  responseServiceActive,
  emergencyContact,
}: {
  homeId: string
  address?: THomeAddress
  location?: ILocation
  emergencyContact: THome["emergency_contact"]
  responseServiceActive?: boolean
}) {
  const { org } = useOrganization()
  const [currentStep, setCurrentStep] = useState(0)
  const [homeAddress, setHomeAddress] = useState<THomeAddress | undefined>(
    address
  )
  const [homeLocation, setHomeLocation] = useState<ILocation | undefined>(
    location
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const { goBack } = useRouter()

  const patchHome = usePatchHome()
  const { t, langKeys } = useTranslate()

  function handleHomeAddressStep(address: THomeAddress, location: ILocation) {
    // Spread address here since otherwise it might overwrite things not handled by the form, like notes
    setHomeAddress((prev) => ({ ...prev, ...address }))
    setHomeLocation(location)
    setCurrentStep((currStep) => currStep + 1)
  }

  function handleHomeEmergencyContactStep({
    notes,
    name,
    phone_number,
  }: Partial<IEmergencyContact> & Pick<THomeAddress, "notes">) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    function onPatchError(error: AxiosError<any, any>) {
      if (
        error.response?.data?.error_key ===
        ResponseServiceConfigurationError.NOT_AVAILABLE_AT_LOCATION
      ) {
        patchHome.reset()
        setDialogOpen(true)
      } else {
        ErrorService.captureException(error)
      }
    }

    if (!homeAddress || !homeLocation) {
      return
    }

    const data: Partial<THome> = {
      address: homeAddress ? { ...homeAddress, notes } : undefined,
      location: homeLocation,
      timezone: homeLocation
        ? getTimeZoneFromLoc(homeLocation.latitude, homeLocation.longitude)
        : undefined,

      emergency_contact:
        name || phone_number
          ? { name: name ?? "", phone_number: phone_number ?? "" }
          : undefined,
    }

    return patchHome.mutate(
      { orgId: org.id, homeId, data },
      {
        onSuccess: () => goBack({ defaultPath: Routes.Home.location(homeId) }),
        onError: onPatchError,
      }
    )
  }

  const setupForGuardService = !!responseServiceActive

  const stepper = [
    {
      component: (
        <HomeAddressStep
          onSuccess={handleHomeAddressStep}
          address={homeAddress}
          location={homeLocation}
          setupForGuardService={setupForGuardService}
        />
      ),
      nextButtonProps: {
        form: HOME_ADDRESS_FORM_ID,
      },
      nextButtonLabel: t(langKeys.continue),
      onBack: () => goBack({ defaultPath: Routes.Home.location(homeId) }),
    },
    {
      component: (
        <HomeMapStep
          onChangeLocation={setHomeLocation}
          location={homeLocation}
          error={patchHome.error || null}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
    },
    {
      component: (
        <HomeEmergencyContactStep
          onSuccess={handleHomeEmergencyContactStep}
          setupForGuardService={setupForGuardService}
          emergencyContact={emergencyContact}
          notes={homeAddress?.notes}
        />
      ),
      nextButtonProps: {
        form: HOME_EMERGENCY_CONTACT_FORM_ID,
        loading: patchHome.isLoading,
      },
      nextButtonLabel: t(langKeys.save),
    },
  ]

  return (
    <>
      <FullscreenWizard
        title={t(langKeys.edit_home_information)}
        steps={stepper}
        currentStep={currentStep}
        onClose={() => goBack({ defaultPath: Routes.Home.location(homeId) })}
        onNext={() => setCurrentStep((currStep) => currStep + 1)}
        onBack={() => setCurrentStep((currStep) => currStep - 1)}
      />
      <ConfirmDialog
        title={t(
          langKeys.home_address_wizard_response_service_not_available_title
        )}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        loading={patchHome.isLoading}
        description={t(
          langKeys.home_address_wizard_response_service_not_available_description
        )}
        onConfirm={() => setDialogOpen(false)}
        centerActionButtons={true}
        confirmLabel={t(langKeys.ok)}
      />
    </>
  )
}
